import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/utbot-site-staging/utbot-site-staging/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With UnitTestBot you can generate tests`}</p>
    <ul>
      <li parentName="ul">{`for a package,`}</li>
      <li parentName="ul">{`for a single class or a set of classes,`}</li>
      <li parentName="ul">{`for a method or a set of methods.`}</li>
    </ul>
    <h2 {...{
      "id": "how-to-generate-and-run-tests-with-default-configuration"
    }}>{`How to generate and run tests with default configuration`}</h2>
    <ol>
      <li parentName="ol">{`Open your Java project in IntelliJ IDEA.`}</li>
      <li parentName="ol">{`Choose the required package, class or set of classes in the Project tool window.`}<br></br>{`
Right-click and choose `}<strong parentName="li">{`Generate Tests with UnitTestBot...`}</strong>{` or press `}<strong parentName="li">{`Alt+Shift+U`}</strong>{`.`}</li>
      <li parentName="ol">{`Alternatively, in the Editor, place the caret at the required class or method.`}<br></br>{`
Press `}<strong parentName="li">{`Alt+Shift+U`}</strong>{` or right-click and choose `}<strong parentName="li">{`Generate...`}</strong>{` > `}<strong parentName="li">{`Tests with UnitTestBot`}</strong>{`.`}</li>
      <li parentName="ol">{`In the `}<strong parentName="li">{`Generate Tests with UnitTestBot`}</strong>{` window tick the classes or methods you'd like to cover with tests and press `}<strong parentName="li">{`Generate Tests`}</strong>{` or `}<strong parentName="li">{`Generate and Run`}</strong>{`.`}<br></br></li>
    </ol>
    <p>{`Now you can see the resulting test class or classes in the Editor.`}</p>
    <p><em parentName="p">{`Note:`}</em>{` if you re-generate the test for a given class or method, it will erase the previously generated test for this unit. To prevent this, rename or move the tests you want to keep. For more information see `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Fine-tune-test-generation#test-sources-root"
      }}><strong parentName="a">{`Test sources root`}</strong></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      